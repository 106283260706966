import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import BreaksPage from './BreaksPage';
import CompensationsPage from './CompensationsPage';
import OvertimePage from './OvertimePage';
import AccommodationPage from './AccommodationPage';

import { LiveInsOnboardingProvider } from '../../context/LiveInsOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useLiveInsOnboarding } from '../../hooks/useLiveInsOnboarding';

import { coreRoutes } from '../../../core/routes/constants';

import { StyledLiveInsPage } from './styles';

const LiveInsOnboardingContent = ({ children }) => {
	const history = useHistory();
	const { liveInsOnboardingState } = useLiveInsOnboarding();
	const { hasComppletedPlatformOnboarding, hasCompletedLiveInsWizard, hasDocumentsApproval } =
		useAuth();

	useLayoutEffect(() => {
		const steps = Object.values(liveInsOnboardingState);
		const currentStep = steps.find((step) => !step.isCompleted);
		if (currentStep) {
			history.push(currentStep.route);
		} else {
			if (hasComppletedPlatformOnboarding && !hasDocumentsApproval) {
				history.push(coreRoutes.documents);
			}
		}
	}, [liveInsOnboardingState, history]);

	return <StyledLiveInsPage>{children}</StyledLiveInsPage>;
};

const LiveInsOnboardingPage = (props) => {
	return (
		<LiveInsOnboardingProvider>
			<LiveInsOnboardingContent {...props} />
		</LiveInsOnboardingProvider>
	);
};

export { BreaksPage, CompensationsPage, OvertimePage, AccommodationPage };
export default LiveInsOnboardingPage;
