import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledLiveInsForm = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	gap: 30px;
	width: 70%;
`;

export const StyledLiveInsFormHeading = styled.h2`
	line-height: 1.4;
	font-size: 1.2em;
	color: ${colors.forms.fontColor};
`;

export const StyledLiveInsWrwaper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 0.5rem;

	@media ${mediaQueries.tablet} {
		gap: 1rem;
		flex-direction: row;
	}
`;

export const StyledLiveInfFormContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	flex-direction: column;
	gap: 1.5rem;
	margin-bottom: 1.5rem;

	@media ${mediaQueries.tablet} {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const StyledLiveInFormDescription = styled.div`
	display: flex;
	width: 100%;
	padding: 25px;
	border-radius: 0.5rem;
	font-size: 14px;
	border: 3px dashed rgba(43, 105, 166, 0.3);

	@media ${mediaQueries.tablet} {
		width: 30%;
	}
`;
