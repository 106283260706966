import React from 'react';

import PageHeading from '../../../../../corev2/PageHeading';
import Text from '../../../../../corev2/Text';
import PricingCalculator from '../../../../../corev2/PricingCalculator';

import { useAuth } from '../../../core/hooks/useAuth';

import { StyledLiveInsSummary, StyledHeadingContainer } from './styles';

const LivesInsSummary = () => {
	const { authUser } = useAuth();
	const carePlan = authUser.carePlan;

	// Initialize formatted texts array
	const formattedTexts = [];

	// Manually check each field and push formatted text accordingly
	if (carePlan.live_in_break_hours !== undefined && carePlan.live_in_break_hours !== null) {
		formattedTexts.push(
			`I agree to ${parseFloat(carePlan.live_in_break_hours).toFixed(2)} hour(s) of break(s).`
		);
	}

	if (carePlan.live_in_sleep_hours !== undefined && carePlan.live_in_sleep_hours !== null) {
		formattedTexts.push(
			`I agree to ${parseFloat(carePlan.live_in_sleep_hours).toFixed(
				2
			)} hour(s) of sleep time.`
		);
	}

	if (carePlan.live_in_below_minimum_rest_time_acknowledgment) {
		formattedTexts.push('I acknowledge that I am providing less than 8 hours of rest time.');
	}

	if (carePlan.daily_rate !== undefined && carePlan.daily_rate !== null) {
		formattedTexts.push(
			`I agree to pay $${parseFloat(carePlan.daily_rate)} per hour for this 24-hour period.`
		);
	}

	if (carePlan.live_in_below_minimum_wage_acknowledgment) {
		formattedTexts.push(
			'I acknowledge that I am paying less than the minimum wage in my state.'
		);
	}

	if (carePlan.live_in_days_of_care !== undefined && carePlan.live_in_days_of_care !== null) {
		formattedTexts.push(
			`I require ${parseFloat(carePlan.live_in_days_of_care).toFixed(2)} days of care.`
		);
	}

	if (
		carePlan.live_in_care_pros_required !== undefined &&
		carePlan.live_in_care_pros_required !== null
	) {
		formattedTexts.push(
			`I would like to hire ${parseFloat(carePlan.live_in_care_pros_required).toFixed(
				2
			)} Care Provider(s).`
		);
	}

	if (
		carePlan.live_in_bedroom_available !== undefined &&
		carePlan.live_in_bedroom_available !== null
	) {
		formattedTexts.push(
			carePlan.live_in_bedroom_available
				? 'I will provide a private bedroom.'
				: 'I will not provide a private bedroom.'
		);
	}

	if (
		carePlan.live_in_bathroom_available !== undefined &&
		carePlan.live_in_bathroom_available !== null
	) {
		formattedTexts.push(
			carePlan.live_in_bathroom_available
				? 'I will provide a private bathroom.'
				: 'I will not provide a private bathroom.'
		);
	}

	if (
		carePlan.live_in_miles_compensated !== undefined &&
		carePlan.live_in_miles_compensated !== null
	) {
		formattedTexts.push(
			carePlan.live_in_miles_compensated
				? 'Miles will be compensated.'
				: 'Miles will not be compensated.'
		);
	}

	if (carePlan.live_in_meals_provided !== undefined && carePlan.live_in_meals_provided !== null) {
		formattedTexts.push(
			carePlan.live_in_meals_provided
				? 'Meals will be provided.'
				: 'Meals will not be provided.'
		);
	}

	return (
		<StyledLiveInsSummary>
			<StyledHeadingContainer>
				<PageHeading>Your Live-Ins Summary</PageHeading>
			</StyledHeadingContainer>

			{formattedTexts.map((text, index) => (
				<Text key={index}>{text}</Text>
			))}

			{/* Extract dailyRate from carePlan if applicable */}
			<PricingCalculator dailyRate={Number(carePlan.daily_rate) * 100} />
		</StyledLiveInsSummary>
	);
};

export default LivesInsSummary;
