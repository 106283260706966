import { z } from 'zod';

const personalInfoFormValidationSchema = z.object({
	firstName: z
		.string()
		.min(1, 'First name is required.')
		.max(32, 'First name is too long.')
		.regex(/^\S+$/, 'First name cannot contain spaces.'), // Disallow spaces
	lastName: z
		.string()
		.min(1, 'Last name is required.')
		.max(32, 'Last name is too long.')
		.regex(/^\S+$/, 'Last name cannot contain spaces.'), // Disallow spaces
	displayName: z
		.string()
		.min(1, 'Display name is required.')
		.max(32, 'Display name is too long.')
		.regex(/^\S+$/, 'Display name cannot contain spaces.'), // Disallow spaces
});

export default personalInfoFormValidationSchema;
