import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const verifyCareProPhone = async ({ phone, email }) => {
	try {
		const data = await api.graph({
			query: `mutation {
				verifyCareProPhone(phone_number: "${phone}", email: "${email}")
			}`,
		});

		return data.verifyProviderPhone;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useVerifyCareProPhoneMutation = () => {
	return useMutation({
		mutationKey: ['verifyCareProPhone'],
		mutationFn: (values) => {
			return verifyCareProPhone(values);
		},
	});
};
