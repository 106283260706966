import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AccommodationForm from '../../../components/AccommodationForm';

import { useUpdateCarePlanInfoMutation } from '../../../../core/api/mutations/useUpdateCarePlanInfoMutation';
import { useHandleCompletedLiveInsWizard } from '../../../api/mutations/useHandleCompletedLiveInsWizardMutation';
import { useLiveInsOnboarding } from '../../../hooks/useLiveInsOnboarding';
import { useAuth } from '../../../../core/hooks/useAuth';

import { authRoutes } from '../../../routes/constants';

import { StyledLiveInsFormHeading } from './styles';

const AccommodationPage = () => {
	const [serverError, setServerError] = useState('');

	const updateCarePlanInfo = useUpdateCarePlanInfoMutation();
	const handleCompletedLiveInsWizard = useHandleCompletedLiveInsWizard();
	const { liveInsOnboardingState, mutateLiveInsOnboardingState } = useLiveInsOnboarding();
	const { authUser, modifyAuthUser } = useAuth();
	const history = useHistory();

	const updateCarePlanInfoHandler = async (values) => {
		try {
			const data = await updateCarePlanInfo.mutateAsync({
				carePlanId: authUser.carePlan.id,
				carePlanInfo: {
					live_in_bedroom_available:
						values.live_in_bedroom_available === 'on' ? true : false,
					live_in_bathroom_available:
						values.live_in_bathroom_available === 'on' ? true : false,
					live_in_miles_compensated:
						values.live_in_miles_compensated === 'on' ? true : false,
					live_in_meals_provided: values.live_in_meals_provided === 'on' ? true : false,
				},
			});

			await handleCompletedLiveInsWizard.mutateAsync({ carePlanId: authUser.carePlan.id });

			mutateLiveInsOnboardingState('accommodation', {
				...liveInsOnboardingState.accommodation,
				data: {
					live_in_bedroom_available: values.live_in_bedroom_available,
					live_in_bathroom_available: values.live_in_bathroom_available,
					live_in_miles_compensated: values.live_in_miles_compensated,
					live_in_meals_provided: values.live_in_meals_provided,
				},
				isCompleted: true,
			});

			history.push(authRoutes.onboarding.liveIns.summary);
		} catch (error) {
			console.error('Updating care plan failed', error);
			setServerError(error);
		}
	};

	const renderInitialValues = () => {
		if (authUser.carePlan) {
			return {
				live_in_bedroom_available: authUser.carePlan.live_in_bedroom_available,
				live_in_bathroom_available: authUser.carePlan.live_in_bathroom_available,
				live_in_miles_compensated: authUser.carePlan.live_in_miles_compensated,
				live_in_meals_provided: authUser.carePlan.live_in_meals_provided,
			};
		}

		return {
			live_in_bedroom_available: false,
			live_in_bathroom_available: false,
			live_in_miles_compensated: false,
			live_in_meals_provided: false,
		};
	};

	return (
		<>
			<StyledLiveInsFormHeading>Accommodation</StyledLiveInsFormHeading>
			<AccommodationForm
				initialValues={renderInitialValues()}
				updateCarePlanInfoHandler={updateCarePlanInfoHandler}
				serverError={serverError}
			/>
		</>
	);
};

export default AccommodationPage;
