import React, { useState } from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { PrimaryButton } from '../../../../../corev2/Buttons';
import { FieldGroup, ErrorMessage } from '../../../../../corev2/Forms';
import { TextInput, Checkbox, Select } from '../../../core/components/LiveInsFormElements';

import compensationsValidationSchema from './validation';

import { usStatesMinimumWage } from '../../../core/constants';

import {
	StyledLiveInsForm,
	StyledLiveInsFormHeading,
	StyledLiveInsWrwaper,
	StyledLiveInfFormContainer,
	StyledLiveInFormDescription,
} from './styles';

const CompensationsForm = (props) => {
	const { serverError, initialValues, updateCarePlanInfoHandler } = props;
	const [belowMinimumWageAcknowledgment, setBelowMinimumWageAcknowledgment] = useState(false);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: async (values) => {
			const dailyRate = parseFloat(values.daily_rate);
			const liveInState = values.live_in_state;

			const minimumWage = usStatesMinimumWage.find(
				(usStatesMinimumWage) => usStatesMinimumWage.name === liveInState
			).minimumWage;

			if (
				dailyRate < minimumWage &&
				values.live_in_below_minimum_wage_acknowledgment !== true
			) {
				return setBelowMinimumWageAcknowledgment(true);
			} else {
				setBelowMinimumWageAcknowledgment(false);
			}

			await updateCarePlanInfoHandler(values);
		},
		validationSchema: toFormikValidationSchema(compensationsValidationSchema),
	});

	// Handle checkbox state change
	const handleAcknowledgement = (e) => {
		formik.setFieldValue('live_in_below_minimum_wage_acknowledgment', e.target.checked);
	};

	return (
		<>
			<StyledLiveInsWrwaper>
				<StyledLiveInsForm onSubmit={formik.handleSubmit}>
					<StyledLiveInfFormContainer>
						<FieldGroup>
							<Select
								name='live_in_state'
								options={usStatesMinimumWage.map((item) => item.name)}
								label='Which state do you live in?'
								type='text'
								value={formik.values.live_in_state}
								onChange={(e) => {
									const selectedOptions = Array.from(
										e.target.selectedOptions,
										(option) => option.value
									);
									formik.setFieldValue('live_in_state', selectedOptions[0]);
								}}
								onBlur={formik.handleBlur}
								isRequired
							/>

							{formik.touched.live_in_state && formik.errors.live_in_state && (
								<ErrorMessage>{formik.errors.live_in_state}</ErrorMessage>
							)}
						</FieldGroup>

						<FieldGroup>
							<TextInput
								name='daily_rate'
								label='Daily rate'
								type='number'
								value={formik.values.daily_rate}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isRequired
							/>

							{formik.touched.daily_rate && formik.errors.daily_rate && (
								<ErrorMessage>{formik.errors.daily_rate}</ErrorMessage>
							)}
						</FieldGroup>
					</StyledLiveInfFormContainer>

					{belowMinimumWageAcknowledgment && (
						<Checkbox
							name='live_in_below_minimum_wage_acknowledgment'
							label="I acknowledge that I'm paying less than minimum wage for my state."
							onChange={handleAcknowledgement}
						/>
					)}

					{serverError && <ErrorMessage>{serverError}</ErrorMessage>}
					<PrimaryButton type='submit'>Submit</PrimaryButton>
				</StyledLiveInsForm>

				<StyledLiveInFormDescription>insert description</StyledLiveInFormDescription>
			</StyledLiveInsWrwaper>
		</>
	);
};

export default CompensationsForm;
