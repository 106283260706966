import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccountInfoPage from './AccountInfoPage';
import PersonalInfoPage from './PersonalInfoPage';
import AuthCodePage from './AuthCodePage';

import Steps from '../../../../../corev2/Steps';

import { PlatformOnboardingProvider } from '../../context/PlatformOnboardingContext';
import { usePlatformOnboarding } from '../../hooks/usePlatformOnboarding';

import { carePlanRoutes } from '../../../careplan/routes/constants';

import { StyledPlatformOnboardingPage, StyledStepsContainer } from './styles';

const PlatformOnboardingContent = ({ children }) => {
	const history = useHistory();
	const location = useLocation();

	const { platformOnboardingState } = usePlatformOnboarding();

	useLayoutEffect(() => {
		const steps = Object.values(platformOnboardingState);

		const currentStep = steps.find((step) => !step.isCompleted);

		if (currentStep && location.pathname !== currentStep.route) {
			history.push(currentStep.route);
		} else if (!currentStep && location.pathname !== carePlanRoutes.home) {
			history.push(carePlanRoutes.home);
		}
	}, [platformOnboardingState, history]);

	return (
		<StyledPlatformOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={platformOnboardingState} />
			</StyledStepsContainer>
			{children}
		</StyledPlatformOnboardingPage>
	);
};

const PlatformOnboardingPage = (props) => {
	return (
		<PlatformOnboardingProvider>
			<PlatformOnboardingContent {...props} />
		</PlatformOnboardingProvider>
	);
};

export { AccountInfoPage, PersonalInfoPage, AuthCodePage };
export default PlatformOnboardingPage;
