import React, { useState } from 'react';

import CompensationsForm from '../../../components/CompensationsForm';

import { useUpdateCarePlanInfoMutation } from '../../../../core/api/mutations/useUpdateCarePlanInfoMutation';
import { useLiveInsOnboarding } from '../../../hooks/useLiveInsOnboarding';
import { useAuth } from '../../../../core/hooks/useAuth';

import { usStatesMinimumWage } from '../../../../core/constants/usStatesMinimumWage';

import { StyledLiveInsFormHeading } from './styles';

const CompensationsPage = () => {
	const [serverError, setServerError] = useState('');

	const updateCarePlanInfo = useUpdateCarePlanInfoMutation();
	const { liveInsOnboardingState, mutateLiveInsOnboardingState } = useLiveInsOnboarding();
	const { authUser, hasCompletedLiveInsWizard, modifyAuthUser } = useAuth();

	const updateCarePlanInfoHandler = async (values) => {
		try {
			const data = await updateCarePlanInfo.mutateAsync({
				carePlanId: authUser.carePlan.id,
				carePlanInfo: {
					live_in_state: values.live_in_state,
					daily_rate: values.daily_rate,
					live_in_below_minimum_wage_acknowledgment:
						values.live_in_below_minimum_wage_acknowledgment,
				},
			});

			mutateLiveInsOnboardingState('compensations', {
				...liveInsOnboardingState.compensations,
				data: {
					live_in_state: values.live_in_state,
					daily_rate: values.daily_rate,
					live_in_below_minimum_wage_acknowledgment:
						values.live_in_below_minimum_wage_acknowledgment,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Updating care plan failed', error);
			setServerError(error);
		}
	};

	const renderInitialValues = () => {
		if (hasCompletedLiveInsWizard) {
			return {
				live_in_state: authUser.carePlan.live_in_state,
				daily_rate: authUser.carePlan.daily_rate,
				live_in_below_minimum_wage_acknowledgment: false,
			};
		}

		return {
			live_in_state: usStatesMinimumWage[0].name,
			daily_rate: 0,
			live_in_below_minimum_wage_acknowledgment: false,
		};
	};

	return (
		<>
			<StyledLiveInsFormHeading>Compensations</StyledLiveInsFormHeading>
			<CompensationsForm
				initialValues={renderInitialValues()}
				updateCarePlanInfoHandler={updateCarePlanInfoHandler}
				serverError={serverError}
			/>
		</>
	);
};

export default CompensationsPage;
