import React, { useState } from 'react';

import BreaksForm from '../../../components/BreaksForm';

import { useUpdateCarePlanInfoMutation } from '../../../../core/api/mutations/useUpdateCarePlanInfoMutation';
import { useLiveInsOnboarding } from '../../../hooks/useLiveInsOnboarding';
import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledLiveInsFormHeading } from './styles';

const BreaksPage = () => {
	const [serverError, setServerError] = useState('');

	const updateCarePlanInfo = useUpdateCarePlanInfoMutation();
	const { liveInsOnboardingState, mutateLiveInsOnboardingState } = useLiveInsOnboarding();
	const { authUser, hasCompletedLiveInsWizard } = useAuth();

	const updateCarePlanInfoHandler = async (values) => {
		try {
			const data = await updateCarePlanInfo.mutateAsync({
				carePlanId: authUser.carePlan.id,
				carePlanInfo: {
					live_in_break_hours: values.live_in_break_hours,
					live_in_sleep_hours: values.live_in_sleep_hours,
					live_in_below_minimum_rest_time_acknowledgment:
						values.live_in_below_minimum_rest_time_acknowledgment,
				},
			});

			mutateLiveInsOnboardingState('breaks', {
				...liveInsOnboardingState.breaks,
				data: {
					live_in_break_hours: values.live_in_break_hours,
					live_in_sleep_hours: values.live_in_sleep_hours,
					live_in_below_minimum_rest_time_acknowledgment:
						values.live_in_below_minimum_rest_time_acknowledgment,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Updating care plan failed', error);
			setServerError(error);
		}
	};

	const renderInitialValues = () => {
		if (hasCompletedLiveInsWizard) {
			return {
				live_in_break_hours: authUser.carePlan.live_in_break_hours,
				live_in_sleep_hours: authUser.carePlan.live_in_sleep_hours,
				live_in_below_minimum_rest_time_acknowledgment: false,
			};
		}

		return {
			live_in_break_hours: '',
			live_in_sleep_hours: '',
			live_in_below_minimum_rest_time_acknowledgment: false,
		};
	};

	return (
		<>
			<StyledLiveInsFormHeading>Breaks</StyledLiveInsFormHeading>
			<BreaksForm
				initialValues={renderInitialValues()}
				updateCarePlanInfoHandler={updateCarePlanInfoHandler}
				serverError={serverError}
			/>
		</>
	);
};

export default BreaksPage;
