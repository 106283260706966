import React, { useState } from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { PrimaryButton } from '../../../../../corev2/Buttons';
import { FieldGroup, ErrorMessage } from '../../../../../corev2/Forms';
import { TextInput, Checkbox } from '../../../core/components/LiveInsFormElements';

import breaksFormValidationSchema from './validation';

import {
	StyledLiveInsForm,
	StyledLiveInsWrwaper,
	StyledLiveInfFormContainer,
	StyledLiveInFormDescription,
} from './styles';

const BreaksForm = (props) => {
	const { initialValues, serverError, updateCarePlanInfoHandler } = props;
	const [belowMinimumRestTimeAcknowledgment, setBelowMinimumRestTimeAcknowledgment] =
		useState(false);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: async (values) => {
			const breakHours = parseFloat(values.live_in_break_hours);
			const sleepHours = parseFloat(values.live_in_sleep_hours);

			// Check if the sum of break and sleep hours is less than 8
			if (
				breakHours + sleepHours < 8 &&
				!values.live_in_below_minimum_rest_time_acknowledgment
			) {
				return setBelowMinimumRestTimeAcknowledgment(true);
			} else {
				setBelowMinimumRestTimeAcknowledgment(false);
			}

			await updateCarePlanInfoHandler(values);
		},
		validationSchema: toFormikValidationSchema(breaksFormValidationSchema),
	});

	// Handle checkbox state change
	const handleAcknowledgement = (e) => {
		setBelowMinimumRestTimeAcknowledgment(e.target.checked);
		formik.setFieldValue(
			'live_in_below_minimum_rest_time_acknowledgment',
			e.target.checked ? true : false
		);
	};

	return (
		<>
			<StyledLiveInsWrwaper>
				<StyledLiveInsForm onSubmit={formik.handleSubmit}>
					<StyledLiveInfFormContainer>
						<FieldGroup>
							<TextInput
								name='live_in_break_hours'
								label='How many hours of breaks will you provide?'
								type='number'
								value={formik.values.live_in_break_hours}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isRequired
							/>

							{formik.touched.live_in_break_hours &&
								formik.errors.live_in_break_hours && (
									<ErrorMessage>{formik.errors.live_in_break_hours}</ErrorMessage>
								)}
						</FieldGroup>

						<FieldGroup>
							<TextInput
								name='live_in_sleep_hours'
								label='How many hours of sleep time will you provide?'
								type='number'
								value={formik.values.live_in_sleep_hours}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isRequired
							/>

							{formik.touched.live_in_sleep_hours &&
								formik.errors.live_in_sleep_hours && (
									<ErrorMessage>{formik.errors.live_in_sleep_hours}</ErrorMessage>
								)}
						</FieldGroup>
					</StyledLiveInfFormContainer>

					{belowMinimumRestTimeAcknowledgment && (
						<Checkbox
							name='live_in_below_minimum_rest_time_acknowledgment'
							label="I acknowledge that I'm providing less than 8 hours of rest time."
							onChange={handleAcknowledgement}
						/>
					)}

					{serverError && <ErrorMessage>{serverError}</ErrorMessage>}
					<PrimaryButton type='submit'>Submit</PrimaryButton>
				</StyledLiveInsForm>

				<StyledLiveInFormDescription>insert description</StyledLiveInFormDescription>
			</StyledLiveInsWrwaper>
		</>
	);
};

export default BreaksForm;
