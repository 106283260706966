import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
	StyledOnboardingButton,
	StyledBlueCheckMark,
	StyledInformationContainer,
	StyledPlatformOnboardingTitle,
} from './styles';

const OnboardingButton = (props) => {
	const { text, isCompleted, onClickHandler, isDisabled } = props;

	return (
		<StyledOnboardingButton
			isCompleted={isCompleted}
			isDisabled={isDisabled ? isDisabled : false}
			onClick={isCompleted ? undefined : onClickHandler}
		>
			<StyledBlueCheckMark>
				{isCompleted && <FontAwesomeIcon size='xs' icon={faCheck} />}
			</StyledBlueCheckMark>

			<StyledInformationContainer>
				<StyledPlatformOnboardingTitle>{text}</StyledPlatformOnboardingTitle>
			</StyledInformationContainer>
		</StyledOnboardingButton>
	);
};

export default OnboardingButton;
