import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { PrimaryButton } from '../../../../../corev2/Buttons';
import { FieldGroup, ErrorMessage } from '../../../../../corev2/Forms';
import { TextInput, Checkbox } from '../../../core/components/LiveInsFormElements';

import overtimeFormValidation from './validation';

import {
	StyledLiveInsForm,
	StyledLiveInsFormHeading,
	StyledLiveInsWrwaper,
	StyledLiveInfFormContainer,
	StyledLiveInFormDescription,
} from './styles';

const OvertimeForm = (props) => {
	const { initialValues, serverError, updateCarePlanInfoHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			updateCarePlanInfoHandler(values);
		},
		validationSchema: toFormikValidationSchema(overtimeFormValidation),
	});

	return (
		<StyledLiveInsWrwaper>
			<StyledLiveInsForm onSubmit={formik.handleSubmit}>
				<StyledLiveInfFormContainer>
					<FieldGroup>
						<TextInput
							name='live_in_days_of_care'
							label='How many days of care do you require?'
							type='number'
							value={formik.values.live_in_days_of_care}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isRequired
						/>

						{formik.touched.live_in_days_of_care &&
							formik.errors.live_in_days_of_care && (
								<ErrorMessage>{formik.errors.live_in_days_of_care}</ErrorMessage>
							)}
					</FieldGroup>

					<FieldGroup>
						<TextInput
							name='live_in_care_pros_required'
							label='How many Care Pros do you require?'
							type='number'
							value={formik.values.live_in_care_pros_required}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isRequired
						/>

						{formik.touched.live_in_care_pros_required &&
							formik.errors.live_in_care_pros_required && (
								<ErrorMessage>
									{formik.errors.live_in_care_pros_required}
								</ErrorMessage>
							)}
					</FieldGroup>
				</StyledLiveInfFormContainer>

				{serverError && <ErrorMessage>{serverError}</ErrorMessage>}
				<PrimaryButton type='submit'>Submit</PrimaryButton>
			</StyledLiveInsForm>

			<StyledLiveInFormDescription>insert description</StyledLiveInFormDescription>
		</StyledLiveInsWrwaper>
	);
};

export default OvertimeForm;
