import React, { useState } from 'react';

import OvertimeForm from '../../../components/OvertimeForm';

import { useUpdateCarePlanInfoMutation } from '../../../../core/api/mutations/useUpdateCarePlanInfoMutation';
import { useLiveInsOnboarding } from '../../../hooks/useLiveInsOnboarding';
import { useAuth } from '../../../../core/hooks/useAuth';

import { StyledLiveInsFormHeading } from './styles';

const OvertimePage = () => {
	const [serverError, setServerError] = useState('');

	const updateCarePlanInfo = useUpdateCarePlanInfoMutation();
	const { liveInsOnboardingState, mutateLiveInsOnboardingState } = useLiveInsOnboarding();
	const { authUser, hasCompletedLiveInsWizard } = useAuth();

	const updateCarePlanInfoHandler = async (values) => {
		try {
			const data = await updateCarePlanInfo.mutateAsync({
				carePlanId: authUser.carePlan.id,
				carePlanInfo: {
					live_in_days_of_care: values.live_in_days_of_care,
					live_in_care_pros_required: values.live_in_care_pros_required,
				},
			});

			mutateLiveInsOnboardingState('overtime', {
				...liveInsOnboardingState.overtime,
				data: {
					live_in_days_of_care: values.live_in_days_of_care,
					live_in_care_pros_required: values.live_in_care_pros_required,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Updating care plan failed', error);
			setServerError(error);
		}
	};

	const renderInitialValues = () => {
		if (hasCompletedLiveInsWizard) {
			return {
				live_in_days_of_care: authUser.carePlan.live_in_days_of_care,
				live_in_care_pros_required: authUser.carePlan.live_in_care_pros_required,
			};
		}

		return {
			live_in_days_of_care: 0,
			live_in_care_pros_required: 0,
		};
	};

	return (
		<>
			<StyledLiveInsFormHeading>Overtime</StyledLiveInsFormHeading>
			<OvertimeForm
				initialValues={renderInitialValues()}
				updateCarePlanInfoHandler={updateCarePlanInfoHandler}
				serverError={serverError}
			/>
		</>
	);
};

export default OvertimePage;
