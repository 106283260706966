import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LiveInsSummaryPage from '../pages/LiveInsSummaryPage';

import { liveInsRoutes } from './constants';

const LiveInsRoutes = () => {
	return (
		<Switch>
			<Route path={liveInsRoutes.summary}>
				<LiveInsSummaryPage />
			</Route>
		</Switch>
	);
};

export default LiveInsRoutes;
