import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RelationshipPage from './RelationshipPage';
import InfoPage from './InfoPage';
import PersonalPage from './PersonalPage';
import LogisticsPage from './LogisticsPage';
import CareNeedsPage from './CareNeedsPage';

import Steps from '../../../../../corev2/Steps';

import { CarePlanOnboardingProvider } from '../../context/CarePlanOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useCarePlanOnboarding } from '../../hooks/useCarePlanOnboarding';

import { coreRoutes } from '../../../core/routes/constants';

import { StyledCarePlanOnboardingPage, StyledStepsContainer } from './styles';

const CarePlanContent = ({ children }) => {
	const history = useHistory();
	const { carePlanOnboardingState } = useCarePlanOnboarding();
	const { hasComppletedPlatformOnboarding, hasDocumentsApproval } = useAuth();

	useLayoutEffect(() => {
		console.log(carePlanOnboardingState);
		const steps = Object.values(carePlanOnboardingState);
		const currentStep = steps.find((step) => !step.isCompleted);
		if (currentStep) {
			history.push(currentStep.route);
		} else {
			if (hasComppletedPlatformOnboarding && !hasDocumentsApproval) {
				history.push(coreRoutes.documents);
			}
		}
	}, [carePlanOnboardingState, history]);

	return (
		<StyledCarePlanOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={carePlanOnboardingState} />
			</StyledStepsContainer>
			{children}
		</StyledCarePlanOnboardingPage>
	);
};

const CarePlanOnboardingPage = (props) => {
	return (
		<CarePlanOnboardingProvider>
			<CarePlanContent {...props} />
		</CarePlanOnboardingProvider>
	);
};

export { RelationshipPage, InfoPage, PersonalPage, LogisticsPage, CareNeedsPage };
export default CarePlanOnboardingPage;
