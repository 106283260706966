import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import Text from '../../../../../corev2/Text';
import OnboardingButton from '../../components/OnboardingButton';

import { coreRoutes } from '../../../core/routes/constants';
import { authRoutes } from '../../routes/constants';
import { documentsRoutes } from '../../../documents/routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';

import {
	StyledOnboardingWelcomePage,
	StyledHeadingSection,
	StyledOnboardingButtonsContainer,
} from './styles';

const OnBoardingWelcomePageContent = () => {
	const history = useHistory();

	const {
		authUser,
		isAuthenticated,
		isRegistryApproved,
		hasDefaultPaymentMethod,
		hasDocumentsApproval,
		hasCompletedLiveInsWizard,
		hasCompletedPlatformOnboarding,
		refreshAuthUser,
	} = useAuth();

	useEffect(() => {
		refreshAuthUser();
	}, []);

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.platform.home);
	};

	const goToCarePlanOnboardingPage = () => {
		history.push(authRoutes.onboarding.carePlan.home);
	};

	const goToLiveInsOnboardingPage = () => {
		history.push(authRoutes.onboarding.liveIns.home);
	};

	const goToDocumentsPage = () => {
		history.push(coreRoutes.documents);
	};

	const goToJobPostsPage = () => {
		resetStripeOnboardingState();
		history.push(coreRoutes.jobPosts);
	};

	return (
		<StyledOnboardingWelcomePage>
			<StyledHeadingSection>
				<PageHeading>
					Welcome to the FYI CarePro Community
					{isAuthenticated ? `, ${authUser.name}` : ''}!
				</PageHeading>

				<Text size='extraLarge'>
					Looking for reliable, on-demand care services with flexibility and transparency?
					Join the FYI marketplace and connect with experienced care professionals who
					offer the services you need, on your schedule. With FYI, you have the power to
					choose from a variety of skilled CarePros, set the rates that work for you, and
					collaborate directly with the professionals to get the best care.
					<br />
					<br />
					Say goodbye to waiting and uncertainty—FYI ensures a smooth and timely
					experience for both you and your CarePro, giving you peace of mind with every
					service.
					<br />
					<br />
					Join FYI today and start receiving top-quality care on your terms. Follow the
					steps below to get started!
				</Text>
			</StyledHeadingSection>

			<StyledOnboardingButtonsContainer>
				<OnboardingButton
					text='Create an account or log in'
					onClickHandler={goToOnboardingPage}
					isCompleted={isAuthenticated}
				/>

				<OnboardingButton
					text='Set up your Care Plan'
					onClickHandler={goToCarePlanOnboardingPage}
					isDisabled={!isAuthenticated && !hasCompletedPlatformOnboarding}
					isCompleted={isAuthenticated && hasCompletedPlatformOnboarding}
				/>

				{isAuthenticated &&
					authUser.carePlan &&
					authUser.carePlan.care_type === 'live_in' && (
						<OnboardingButton
							text='Enter your Live In information'
							onClickHandler={goToLiveInsOnboardingPage}
							isDisabled={!isAuthenticated && !hasCompletedPlatformOnboarding}
							isCompleted={isAuthenticated && hasCompletedLiveInsWizard}
						/>
					)}

				<OnboardingButton
					text='Upload supporting documents'
					onClickHandler={goToDocumentsPage}
					isDisabled={!hasCompletedPlatformOnboarding}
					isCompleted={
						isAuthenticated && hasCompletedPlatformOnboarding && hasDocumentsApproval
					}
				/>

				<OnboardingButton
					text='Registry approval'
					onClickHandler={() => {}}
					isCompleted={isRegistryApproved}
					isDisabled={!isAuthenticated || !hasDocumentsApproval}
				/>
			</StyledOnboardingButtonsContainer>
		</StyledOnboardingWelcomePage>
	);
};

const OnBoardingWelcomePage = () => {
	return <OnBoardingWelcomePageContent />;
};

export default OnBoardingWelcomePage;
