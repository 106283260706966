import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckCareProSessionQuery } from '../../api/queries/useCheckCareProSessionQuery';

export const AuthContext = createContext();

const initialAuthState = {
	appIsLoading: true,
	isAuthenticated: false,
	isRegistryApproved: false,
	hasCompletedStripeOnboarding: false,
	isStripeVerified: false,
	hasCompletedProfileOnboarding: false,
	hasCompletedPlatformOnboarding: false,
	pendingStripeDocumentRequirements: false,
	completedDocumentApproval: false,
	authCarePro: null,
	statusUpdate: 'Pending Registry Approval',
};

export const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState(initialAuthState);

	const {
		data,
		isLoading: appIsLoading,
		refetch: refreshAuthCarePro,
		isRefetching: appIsReloading,
	} = useCheckCareProSessionQuery();

	// Update authentication and onboarding states
	useEffect(() => {
		const token = getFromLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);

		if (!appIsLoading && !appIsReloading) {
			if (token && data) {
				const { careProRegistryStatus, professional_statement, stripeStatus } = data;

				// Update whether the user is authenticated based on token existence
				setAuthState({
					isAuthenticated: true,

					appIsLoading: appIsLoading && appIsReloading,

					// Determine if registry approval is granted
					isRegistryApproved: careProRegistryStatus?.registry_approval || false,

					// Verify if Stripe onboarding is completed
					isStripeVerified: stripeStatus?.status === 'verified',

					// Check if Stripe onboarding is not unverified or empty
					hasCompletedStripeOnboarding:
						stripeStatus?.status !== 'unverified' && stripeStatus?.status !== '',

					// Check if the platform onboarding is completed
					hasCompletedPlatformOnboarding:
						careProRegistryStatus?.platform_onboard || false,

					// Determine if profile onboarding is complete
					hasCompletedProfileOnboarding: careProRegistryStatus?.profile_onboard || false,

					// Determine if there are pending Stripe document requirements
					// ensure that the only items that end with '.document' are found if not return false

					pendingStripeDocumentRequirements: (
						stripeStatus?.stripe_data?.currently_due || []
					).every((req) => req.endsWith('.document')),

					// Check if all required documents are approved
					completedDocumentApproval: careProRegistryStatus?.documents_approval || false,

					// Store the CarePro data if a valid token exists
					authCarePro: token ? data : null,

					// Set the status update based on registry approval
					statusUpdate: careProRegistryStatus?.registry_approval
						? 'Approved'
						: 'Pending Registry Approval. Please complete your onboarding.',
				});
			} else {
				// Clear authentication state if no token is found
				setAuthState((prevState) => ({
					...initialAuthState,
					appIsLoading: appIsLoading && appIsReloading,
				}));
				setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
			}
		}
	}, [data, appIsLoading, appIsReloading]);

	// Authentication methods
	const loginCarePro = (token) => {
		// Store the token in localStorage and update state
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, token);
		refreshAuthCarePro();
	};

	const logoutCarePro = () => {
		// Clear localStorage and reset authentication state
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, null);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_LEGACY_AUTH_TOKEN, null);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_PLATFORM_OBOARDING_STATE, null);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_PROFILE_OBOARDING_STATE, null);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_STRIPE_ONBOARDING_STATE, null);
		setAuthState({ ...initialAuthState, appIsLoading: false });
	};

	const value = {
		...authState,
		loginCarePro,
		logoutCarePro,
		refreshAuthCarePro,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
