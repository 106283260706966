import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import AuthCodeForm from '../../../components/AuthCodeForm';

import { useAuth } from '../../../../core/hooks/useAuth';
import { usePlatformOnboarding } from '../../../hooks/usePlatformOnboarding';
import { useRegisterUserMutation } from '../../../api/mutations/useRegisterUserMutation';

import { Notification } from '../../../../core/lib';
import { notifications } from '../../../../core/constants';

import { authRoutes } from '../../../routes/constants';

import { StyledAuthCodePage } from './styles';

const AuthCodePage = () => {
	const history = useHistory();

	const [serverError, setServerError] = useState('');

	const { platformOnboardingState, resetPlatformOnboardingState } = usePlatformOnboarding();
	const { loginUser } = useAuth();
	const registerUserMutation = useRegisterUserMutation();

	const authCodeHandler = async (values) => {
		let registryId = 1;

		const subdomain = window.location.hostname.split('.')[0];

		if (subdomain === 'findyourindependence') {
			// Render content for sub1
			registryId = 2;
		} else if (subdomain === 'philia') {
			// Render content for sub2
			registryId = 3;
		}

		try {
			const { token, user } = await registerUserMutation.mutateAsync({
				email: platformOnboardingState.accountInfo.data.email,
				password: platformOnboardingState.accountInfo.data.password,
				phone: platformOnboardingState.accountInfo.data.phone,
				firstName: platformOnboardingState.personalInfo.data.firstName,
				lastName: platformOnboardingState.personalInfo.data.lastName,
				displayName: platformOnboardingState.personalInfo.data.displayName,
				authCode: values.authCode,
				registryId,
			});

			await loginUser(token);
			Notification(notifications.auth.login);
			resetPlatformOnboardingState();
		} catch (error) {
			setServerError(error[0].message);
			console.error('Auth code verification / Registration failed', error);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.accountInfo);
	};

	return (
		<StyledAuthCodePage>
			<PageHeading>Auth Code</PageHeading>
			<AuthCodeForm
				initialValues={{
					authCode: platformOnboardingState.authCode.data.authCode,
				}}
				authCodeHandler={authCodeHandler}
				serverError={serverError}
			/>

			<TransparentButton size='small' onClick={backButtonHandler}>
				Miss typed?
			</TransparentButton>
		</StyledAuthCodePage>
	);
};

export default AuthCodePage;
