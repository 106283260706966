import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { colors } from '../../corev2/utils';

import UploadPhotoForm from './components/UploadPhotoForm';
import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import { useUpdateCareProMutation } from './api/useUpdateCareProMutation';

import uploadFilesToAWSS3 from '../utility/uploadFilesToAWSS3';

import api from 'scripts/api';

import {
	CARE_PRO_REGISTRY_STATUS_MODEL,
	SHIFT_MODEL,
	CORE_JOB_MODEL,
	PAYMENT_TYPE_MODEL,
	CAREPRO_DOCUMENT_MODEL,
	PROVIDER_MODEL_V2,
	CARE_NEEDS_MODEL,
	STRIPE_STATUS_MODEL,
	PAYOUT_METHOD_MODEL,
	CORE_WORK_TIME_FRAME,
	FORM_MODEL,
} from 'reducers/graphqlModelTypes';
import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';

import { PageContainer } from '../ModelDataViews/CoreView';
import PaymentDataView from '../ModelDataViews/PaymentDataView';
import CareProDataView from '../ModelDataViews/CareProDataView';
import CareProStripeStatusDataView from '../ModelDataViews/CareProStripeStatusDataView';
import CareProRegistryStatusDataView from '../ModelDataViews/CareProRegistryStatus';
import { Title, SubTitle, GridContainer, DataViewButton } from '../ModelDataViews/CoreView';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';

export const SwitchContainer = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: fit-content;
`;

export const Switch = styled.div`
	width: 100px;
	height: 55px;
	background: ${(props) => (props.isOn ? '#4caf50' : '#ccc')};
	border-radius: 25px;
	position: relative;
	transition: background 0.3s;
`;

export const SwitchKnob = styled.div`
	width: 48px;
	height: 48px;
	background: white;
	border-radius: 50%;
	position: absolute;
	top: 3px;
	left: ${(props) => (props.isOn ? '48px' : '1px')};
	transition: left 0.3s;
`;

export const StyledImageContainer = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 1px solid ${colors.primary.primary};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	margin-bottom: 1rem;
	overflow: visible;
	position: relative;
`;

export const StyledEditIconContainer = styled.div`
	position: absolute;
	top: -8px;
	right: -8px;
	width: auto;

	cursor: pointer;
	color: ${({ isActive }) => (isActive ? colors.white : colors.primary.primary)};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
`;

const CareProHandler = (props) => {
	console.log('CareProHandler', props);
	const [isUploadingImage, setIsUploadingImage] = useState(false);
	const [photoEditMode, setPhotoEditMode] = useState(false);
	const [carePro, setCarePro] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { history, location } = props;

	const updateCarePro = useUpdateCareProMutation();

	useEffect(() => {
		const careProId = location.pathname.split('/').pop();

		// ensure the payment_id is a number
		if (isNaN(careProId)) {
			toast.error('Invalid payment id', TOAST_ERROR_CONFIG);
		} else {
			fetchCarePro(careProId);
		}
	}, []);

	const fetchCarePro = async (id) => {
		console.log('fetchCarePro', id);
		setIsLoading(true);
		try {
			const careProRes = await api.graph({
				query: `{
          fetchRegistryCarePro(provider_id: ${id}) {
            ${PROVIDER_MODEL_V2},
          
          
            stripeStatus {
              ${STRIPE_STATUS_MODEL}
            }
            careProRegistryStatus {
              ${CARE_PRO_REGISTRY_STATUS_MODEL}
            }
						skills {
            	id
          	}
          }
        }`,
			});

			/* 
        payoutMethods {
              ${PAYOUT_METHOD_MODEL}
            }
          
            payments {
              ${PAYMENT_TYPE_MODEL}
            }
            documents {
              ${CAREPRO_DOCUMENT_MODEL}
            }
            shifts {
              ${SHIFT_MODEL}
            }
        jobs {
              ${CORE_JOB_MODEL}
            }
         applications {
              id,
              status,
              provider_id,
              job_post_id,
              jobPost {
                id,
                budget_low,
                budget_high,
                status,
                care_plan_id,
                created_at,
                workTimeFrames {
                  ${CORE_WORK_TIME_FRAME}
                }
                carePlan {
                  id,
                  careNeedsSet {
                      ${CARE_NEEDS_MODEL}
                    }
                  carePlanSet {
                    ${CARE_NEEDS_MODEL}
                  }
                 
                }
              }
      */
			if (careProRes.fetchRegistryCarePro) {
				setCarePro(careProRes.fetchRegistryCarePro);
			} else {
				toast.error('CarePro not found', TOAST_ERROR_CONFIG);
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log('fetchCarePro -> err', err);

			toast.error('CarePro not found', TOAST_ERROR_CONFIG);
		}
	};

	const handleUploadPhoto = async (file) => {
		try {
			// Upload the file to AWS S3
			setIsUploadingImage(true);
			const uploadedFile = await uploadFilesToAWSS3({ [carePro.id]: { file } });
			console.log(uploadedFile);
			const updatedCarePro = await updateCarePro.mutateAsync({
				careProId: carePro.id,
				profileInfo: {
					image_url: uploadedFile[carePro.id].url,
				},
			});

			fetchCarePro(carePro.id);
			setIsUploadingImage(false);
		} catch (error) {
			console.error('Photo upload failed:', error);
		}
	};

	const handleClearImage = async () => {
		try {
			setIsUploadingImage(true);
			const updatedCarePro = await updateCarePro.mutateAsync({
				careProId: carePro.id,
				profileInfo: {
					image_url: '',
				},
			});

			fetchCarePro(carePro.id);
			setIsUploadingImage(false);
		} catch (error) {
			console.error('Photo clear failed:', error);
		}
	};

	const handleCareProRegistryStatus = async () => {
		try {
			const setRegistryApprovalRes = await api.graph({
				query: `mutation {
            setRegistryApproval(
              provider_id: ${carePro.id}, 
              status: ${!carePro.careProRegistryStatus.registry_approval}
            ) {
              careProRegistryStatus {
                ${CARE_PRO_REGISTRY_STATUS_MODEL}
              }
            }
          }`,
			});
			console.log(
				'handleCareProRegistryStatus -> setRegistryApprovalRes',
				setRegistryApprovalRes
			);

			if (setRegistryApprovalRes.setRegistryApproval) {
				setCarePro({
					...carePro,
					careProRegistryStatus:
						setRegistryApprovalRes.setRegistryApproval.careProRegistryStatus,
				});
				toast.success('CarePro registry status updated', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			console.log('handleCareProRegistryStatus -> err', err);
		}
	};
	if (isLoading) {
		return <p>loading</p>;
	}

	if (!isLoading && carePro === null) {
		return <p>Something went wrong</p>;
	}

	return (
		<PageContainer
			style={{
				position: 'relative',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: '30px',
					left: '0px',
					cursor: 'pointer',
				}}
			>
				<Icon size='xl' icon={faChevronLeft} onClick={() => history.goBack()} />
			</div>
			<CareProDataView {...carePro} />

			<GridContainer columns={3}>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle>Registry Approval</SubTitle>
					<SwitchContainer onClick={() => handleCareProRegistryStatus()}>
						<Switch isOn={carePro.careProRegistryStatus.registry_approval}>
							<SwitchKnob isOn={carePro.careProRegistryStatus.registry_approval} />
						</Switch>
					</SwitchContainer>
				</ColumnFlex>

				<ColumnFlex margin='0 0 24px 0' center fullWidth>
					<SubTitle>Photo</SubTitle>

					<StyledImageContainer>
						<StyledEditIconContainer onClick={() => setPhotoEditMode(!photoEditMode)}>
							<FontAwesomeIcon icon={faPencilAlt} size='sm' />
						</StyledEditIconContainer>
						<StyledImage src={carePro.image_url}></StyledImage>
					</StyledImageContainer>

					{photoEditMode && (
						<>
							<UploadPhotoForm
								onUpload={handleUploadPhoto}
								onClearImage={handleClearImage}
							/>
							<DataViewButton onClick={handleClearImage}>Remove</DataViewButton>
						</>
					)}
				</ColumnFlex>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> View Documents</SubTitle>
					<DataViewButton
						onClick={() => history.push(`/registry/careproDocs/${carePro.id}`)}
					>
						{' '}
						Docs
					</DataViewButton>
				</ColumnFlex>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> View CarePro Skills</SubTitle>
					<DataViewButton
						onClick={() => history.push(`/registry/formData/${carePro.skills.id}`)}
					>
						{' '}
						Skills
					</DataViewButton>
				</ColumnFlex>
			</GridContainer>

			<GridContainer columns={1}>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> Upload Documents</SubTitle>
					<DataViewButton
						onClick={() => history.push(`/registry/manageCareProDoc/${carePro.id}`)}
					>
						{' '}
						Docs
					</DataViewButton>
				</ColumnFlex>
			</GridContainer>

			<CareProRegistryStatusDataView {...carePro.careProRegistryStatus} />

			<CareProStripeStatusDataView {...carePro.stripeStatus} />
		</PageContainer>
	);
};

export default CareProHandler;
