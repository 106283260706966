import { z } from 'zod';

const personalInfoFormValidationSchema = z.object({
	firstName: z
		.string()
		.min(1, 'First name is required')
		.regex(/^\S+$/, 'Display name cannot contain spaces.'), // Disallow spaces,
	lastName: z
		.string()
		.min(1, 'Last name is required')
		.regex(/^\S+$/, 'Display name cannot contain spaces.'), // Disallow spaces,
	gender: z.enum(['male', 'female', 'transgender', 'non-binary', 'prefer-not-to-respond'], {
		errorMap: (issue, _ctx) => {
			switch (issue.code) {
				case 'invalid_type':
					return { message: 'Gender is required.' };
				case 'invalid_enum_value':
					return { message: 'Gender is required.' };
				default:
					return { message: 'Gender is required.' };
			}
		},
	}),
	displayName: z.string().min(1, 'Display name is required'),
});

export default personalInfoFormValidationSchema;
