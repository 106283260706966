import React, { useState, createContext, useEffect } from 'react';

import { useAuth } from '../../../core/hooks/useAuth';

import { authRoutes } from '../../routes/constants';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

export const LiveInsOnboardingContext = createContext();

const initialLiveInsOnboardingState = {
	breaks: {
		name: 'breaks',
		label: 'Breaks',
		isCompleted: false,
		data: {
			live_in_break_hours: '',
			live_in_sleep_hours: '',
			live_in_below_minimum_rest_time_acknowledgment: false,
		},
		route: authRoutes.onboarding.liveIns.breaks,
	},
	compensations: {
		name: 'compensations',
		label: 'Compensations',
		isCompleted: false,
		data: {
			live_in_state: '',
			daily_rate: '',
			live_in_below_minimum_wage_acknowledgment: false,
		},
		route: authRoutes.onboarding.liveIns.compensations,
	},
	overtime: {
		name: 'overtime',
		label: 'Overtime',
		isCompleted: false,
		data: {
			live_in_days_of_care: '',
			live_in_care_pros_required: '',
		},
		route: authRoutes.onboarding.liveIns.overtime,
	},
	accommodation: {
		name: 'accommodation',
		label: 'Accommodation',
		isCompleted: false,
		data: {
			live_in_bedroom_available: false,
			live_in_bathroom_available: false,
			live_in_miles_compensated: false,
			live_in_meals_provided: false,
		},
		route: authRoutes.onboarding.liveIns.accommodation,
	},
};

export const LiveInsOnboardingProvider = ({ children }) => {
	const [liveInsOnboardingState, setLiveInsOnboardingState] = useState(
		initialLiveInsOnboardingState
	);

	const { authUser } = useAuth();
	useEffect(() => {
		const { carePlan } = authUser;

		const localLiveInsOnboardingState = getFromLocalStorage(
			localStorageKeys.FYI_LIVE_INS_ONBOARDING_STATE
		);

		if (localLiveInsOnboardingState && !carePlan.has_completed_live_ins_wizard) {
			setLiveInsOnboardingState(liveInsOnboardingState);
		} else {
			// Populate data fields with carePlan values
			const updatedLiveInOnboardingsState = {
				breaks: {
					...initialLiveInsOnboardingState.breaks,
					data: {
						...initialLiveInsOnboardingState.breaks.data,
						number_of_break_hours: Number(carePlan.number_of_break_hours) || 0,
						number_of_sleep_hours: Number(carePlan.number_of_sleep_hours) || '',
						below_minimum_rest_time_acknowledgment:
							carePlan.below_minimum_rest_time_acknowledgment || false,
					},
				},
				compensations: {
					...initialLiveInsOnboardingState.compensations,
					data: {
						...liveInsOnboardingState.compensations.data,
						live_in_state: carePlan.live_in_state || '',
						daily_rate: carePlan.daily_rate || 0,
						live_in_below_minimum_wage_acknowledgment:
							carePlan.live_in_below_minimum_wage_acknowledgment || false,
					},
				},
				overtime: {
					...initialLiveInsOnboardingState.overtime,
					data: {
						...liveInsOnboardingState.overtime.data,
						live_in_days_of_care: carePlan.live_in_days_of_care || '',
						live_in_care_pros_required: carePlan.live_in_care_pros_required || '',
					},
				},
				accommodation: {
					...initialLiveInsOnboardingState.accommodation,
					data: {
						...liveInsOnboardingState.accommodation.data,
						live_in_bedroom_available: carePlan.live_in_bedroom_available || false,
						live_in_bathroom_available: carePlan.live_in_bathroom_available || false,
						live_in_miles_compensated: carePlan.live_in_miles_compensated || false,
						live_in_meals_provided: carePlan.live_in_meals_provided || false,
					},
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_LIVE_INS_ONBOARDING_STATE,
				JSON.stringify(updatedLiveInOnboardingsState)
			);
		}
	}, [authUser]);

	const mutateLiveInsOnboardingState = (key, value) => {
		setLiveInsOnboardingState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_LIVE_INS_ONBOARDING_STATE,
				JSON.stringify(newState)
			);
			return newState;
		});
	};

	const resetLiveInsOnboardingState = () => {
		setInLocalStorage(localStorageKeys.FYI_LIVE_INS_STATE);
		setCarePlanState(liveInsOnboardingState);
	};

	const value = {
		liveInsOnboardingState,
		mutateLiveInsOnboardingState,
		resetLiveInsOnboardingState,
	};

	return (
		<LiveInsOnboardingContext.Provider value={value}>
			{children}
		</LiveInsOnboardingContext.Provider>
	);
};
