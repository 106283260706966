import React from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../../../corev2/PageHeading';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import PricingCalculator from '../../../../../corev2/PricingCalculator';
import Text from '../../../../../corev2/Text';

import { useAuth } from '../../../core/hooks/useAuth';
import { coreRoutes } from '../../../core/routes/constants';

import { StyledLiveInsSummaryPage, StyledHeadingContainer } from './styles';

const LivesInsSummaryPage = () => {
	const history = useHistory();
	const { authUser } = useAuth();
	const carePlanData = authUser.carePlan;

	// If carePlanData hasn't loaded yet, show loading message
	if (!carePlanData) {
		return <StyledLiveInsSummaryPage>Loading live-in data...</StyledLiveInsSummaryPage>;
	}

	// Initialize formatted texts array
	const formattedTexts = [];

	// Manually check each field and push formatted text accordingly
	if (
		carePlanData.live_in_break_hours !== undefined &&
		carePlanData.live_in_break_hours !== null
	) {
		formattedTexts.push(
			`I agree to ${parseFloat(carePlanData.live_in_break_hours).toFixed(
				2
			)} hour(s) of break(s).`
		);
	}

	if (
		carePlanData.live_in_sleep_hours !== undefined &&
		carePlanData.live_in_sleep_hours !== null
	) {
		formattedTexts.push(
			`I agree to ${parseFloat(carePlanData.live_in_sleep_hours).toFixed(
				2
			)} hour(s) of sleep time.`
		);
	}

	if (carePlanData.live_in_below_minimum_rest_time_acknowledgment) {
		formattedTexts.push('I acknowledge that I am providing less than 8 hours of rest time.');
	}

	if (carePlanData.daily_rate !== undefined && carePlanData.daily_rate !== null) {
		formattedTexts.push(
			`I agree to pay $${parseFloat(
				carePlanData.daily_rate
			)} per hour for this 24-hour period.`
		);
	}

	if (carePlanData.live_in_below_minimum_wage_acknowledgment) {
		formattedTexts.push(
			'I acknowledge that I am paying less than the minimum wage in my state.'
		);
	}

	if (
		carePlanData.live_in_days_of_care !== undefined &&
		carePlanData.live_in_days_of_care !== null
	) {
		formattedTexts.push(
			`I require ${parseFloat(carePlanData.live_in_days_of_care).toFixed(2)} days of care.`
		);
	}

	if (
		carePlanData.live_in_care_pros_required !== undefined &&
		carePlanData.live_in_care_pros_required !== null
	) {
		formattedTexts.push(
			`I would like to hire ${parseFloat(carePlanData.live_in_care_pros_required).toFixed(
				2
			)} Care Provider(s).`
		);
	}

	if (
		carePlanData.live_in_bedroom_available !== undefined &&
		carePlanData.live_in_bedroom_available !== null
	) {
		formattedTexts.push(
			carePlanData.live_in_bedroom_available
				? 'I will provide a private bedroom.'
				: 'I will not provide a private bedroom.'
		);
	}

	if (
		carePlanData.live_in_bathroom_available !== undefined &&
		carePlanData.live_in_bathroom_available !== null
	) {
		formattedTexts.push(
			carePlanData.live_in_bathroom_available
				? 'I will provide a private bathroom.'
				: 'I will not provide a private bathroom.'
		);
	}

	if (
		carePlanData.live_in_miles_compensated !== undefined &&
		carePlanData.live_in_miles_compensated !== null
	) {
		formattedTexts.push(
			carePlanData.live_in_miles_compensated
				? 'Miles will be compensated.'
				: 'Miles will not be compensated.'
		);
	}

	if (
		carePlanData.live_in_meals_provided !== undefined &&
		carePlanData.live_in_meals_provided !== null
	) {
		formattedTexts.push(
			carePlanData.live_in_meals_provided
				? 'Meals will be provided.'
				: 'Meals will not be provided.'
		);
	}

	const goToOffersPage = () => {
		history.push(coreRoutes.offers);
	};

	return (
		<StyledLiveInsSummaryPage>
			<StyledHeadingContainer>
				<PageHeading>Your Live-Ins Summary</PageHeading>
			</StyledHeadingContainer>

			{formattedTexts.map((text, index) => (
				<Text key={index}>{text}</Text>
			))}

			{/* Extract dailyRate from carePlanData if applicable */}
			<PricingCalculator dailyRate={Number(carePlanData.daily_rate) * 100} />
			<PrimaryButton onClick={goToOffersPage}>View Offers</PrimaryButton>
		</StyledLiveInsSummaryPage>
	);
};

export default LivesInsSummaryPage;
