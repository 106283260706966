import React from 'react';
import { useFormik } from 'formik-latest';

import { PrimaryButton } from '../../../../../corev2/Buttons';
import { FieldGroup, ErrorMessage } from '../../../../../corev2/Forms';
import { Checkbox } from '../../../core/components/LiveInsFormElements';

import {
	StyledLiveInsForm,
	StyledLiveInsFormHeading,
	StyledLiveInsWrwaper,
	StyledLiveInfFormContainer,
	StyledLiveInFormDescription,
} from './styles';

const AccommodationForm = (props) => {
	const { serverError, initialValues, updateCarePlanInfoHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: async (values) => {
			await updateCarePlanInfoHandler(values);
		},
	});

	return (
		<StyledLiveInsWrwaper>
			<StyledLiveInsForm onSubmit={formik.handleSubmit}>
				<StyledLiveInfFormContainer>
					<FieldGroup>
						<Checkbox
							name='live_in_bedroom_available'
							label='Will they have a private bedroom?'
							value={formik.values.private_bedroom_availalable}
							onChange={(e) =>
								formik.setFieldValue('live_in_bedroom_available', e.target.value)
							}
							onBlur={formik.handleBlur}
							isRequired
						/>
						{formik.touched.live_in_bedroom_available &&
							formik.errors.live_in_bedroom_available && (
								<ErrorMessage>
									{formik.errors.live_in_bedroom_available}
								</ErrorMessage>
							)}
					</FieldGroup>

					<FieldGroup>
						<Checkbox
							name='live_in_bathroom_available'
							label='Will they have a private bathroom?'
							value={formik.values.private_bathroom_available}
							onChange={(e) =>
								formik.setFieldValue('live_in_bathroom_available', e.target.value)
							}
							onBlur={formik.handleBlur}
							isRequired
						/>
						{formik.touched.private_bathroom_available &&
							formik.errors.private_bathroom_available && (
								<ErrorMessage>
									{formik.errors.private_bathroom_available}
								</ErrorMessage>
							)}
					</FieldGroup>

					<FieldGroup>
						<Checkbox
							name='live_in_miles_compensated'
							label='Are travel miles compensated?'
							value={formik.values.live_in_miles_compensated}
							onChange={(e) =>
								formik.setFieldValue('live_in_miles_compensated', e.target.value)
							}
							onBlur={formik.handleBlur}
							isRequired
						/>
						{formik.touched.live_in_miles_compensated &&
							formik.errors.live_in_miles_compensated && (
								<ErrorMessage>
									{formik.errors.live_in_miles_compensated}
								</ErrorMessage>
							)}
					</FieldGroup>

					<FieldGroup>
						<Checkbox
							name='live_in_meals_provided'
							label='Will meals be provided?'
							value={formik.values.live_in_meals_provided}
							onChange={(e) =>
								formik.setFieldValue('live_in_meals_provided', e.target.value)
							}
							onBlur={formik.handleBlur}
							isRequired
						/>

						{formik.touched.live_in_meals_provided &&
							formik.errors.live_in_meals_provided && (
								<ErrorMessage>{formik.errors.live_in_meals_provided}</ErrorMessage>
							)}
					</FieldGroup>
				</StyledLiveInfFormContainer>

				{serverError && <ErrorMessage>{serverError}</ErrorMessage>}
				<PrimaryButton type='submit'>Submit</PrimaryButton>
			</StyledLiveInsForm>

			<StyledLiveInFormDescription>insert description</StyledLiveInFormDescription>
		</StyledLiveInsWrwaper>
	);
};

export default AccommodationForm;
