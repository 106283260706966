import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginPage from '../pages/LoginPage';
import PasswordResetPage from '../pages/PasswordResetPage';
import OnboardingPage, {
	PersonalInfoPage,
	AccountInfoPage,
	AuthCodePage,
} from '../pages/PlatformOnboardingPage';
import CarePlanOnboardingPage, {
	RelationshipPage,
	InfoPage,
	PersonalPage,
	LogisticsPage,
	CareNeedsPage,
} from '../pages/CarePlanOnboardingPage';
import LiveInsOnboardingPage, {
	BreaksPage,
	CompensationsPage,
	OvertimePage,
	AccommodationPage,
} from '../pages/LiveInsOnboardingPage';
import OnboardingWelcomePage from '../pages/OnboardingWelcomePage';
import LiveInsSummaryPage from '../pages/LiveInsOnboardingPage/LiveInsSummaryPage';

import { authRoutes } from './constants';

const AuthRoutes = () => {
	return (
		<Switch>
			<Route path={authRoutes.onboarding.home} exact>
				<OnboardingWelcomePage />
			</Route>

			<Route path={authRoutes.login}>
				<LoginPage />
			</Route>

			<Route path={authRoutes.passwordReset}>
				<PasswordResetPage />
			</Route>

			<Route path={authRoutes.onboarding.liveIns.summary}>
				<LiveInsSummaryPage />
			</Route>

			<Route path={authRoutes.onboarding.platform.home}>
				<OnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.platform.personalInfo}>
							<PersonalInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.platform.accountInfo}>
							<AccountInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.platform.authCode}>
							<AuthCodePage />
						</Route>
					</Switch>
				</OnboardingPage>
			</Route>

			<Route path={authRoutes.onboarding.carePlan.home}>
				<CarePlanOnboardingPage>
					<Switch>
						<Route
							path={authRoutes.onboarding.carePlan.relationship}
							component={RelationshipPage}
						/>
						<Route path={authRoutes.onboarding.carePlan.info} component={InfoPage} />
						<Route
							path={authRoutes.onboarding.carePlan.personal}
							component={PersonalPage}
						/>
						<Route
							path={authRoutes.onboarding.carePlan.logistics}
							component={LogisticsPage}
						/>
						<Route
							path={authRoutes.onboarding.carePlan.careNeeds}
							component={CareNeedsPage}
						/>
					</Switch>
				</CarePlanOnboardingPage>
			</Route>

			<Route path={authRoutes.onboarding.liveIns.home}>
				<LiveInsOnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.liveIns.breaks} component={BreaksPage} />
						<Route
							path={authRoutes.onboarding.liveIns.compensations}
							component={CompensationsPage}
						/>
						<Route
							path={authRoutes.onboarding.liveIns.overtime}
							component={OvertimePage}
						/>
						<Route
							path={authRoutes.onboarding.liveIns.accommodation}
							component={AccommodationPage}
						/>
					</Switch>
				</LiveInsOnboardingPage>
			</Route>
		</Switch>
	);
};

export default AuthRoutes;
