import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { authRoutes } from '../../../auth/routes/constants';

export const CarePlanOnboardingContext = createContext();

const initialCarePlanOnboardingState = {
	careCoordinator: {
		name: 'careCoordinator',
		label: 'Care Coordinator',
		isCompleted: false,
		data: {
			relationship: '',
		},
		route: authRoutes.onboarding.carePlan.relationship,
	},
	careRecipient: {
		name: 'careRecipient',
		label: 'Care Recipient',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.carePlan.info,
	},
	contactInfo: {
		name: 'contactInfo',
		label: 'Contact Info',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.carePlan.personal,
	},
	careLocation: {
		name: 'careLocation',
		label: 'Care Location',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.carePlan.logistics,
	},
	careNeeds: {
		name: 'careNeeds',
		label: 'Care Needs',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.carePlan.careNeeds,
	},
};

export const CarePlanOnboardingProvider = ({ children }) => {
	const [carePlanOnboardingState, setCarePlanOnboardingState] = useState(
		initialCarePlanOnboardingState
	);

	useEffect(() => {
		const localCarePlanOnboardingState = getFromLocalStorage(
			localStorageKeys.FYI_CARE_PLAN_ONBOARDING_STATE
		);

		if (localCarePlanOnboardingState) {
			setCarePlanOnboardingState(localCarePlanOnboardingState);
		}
	}, []);

	const mutateCarePlanOnboardingState = (key, value) => {
		setCarePlanOnboardingState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_CARE_PLAN_ONBOARDING_STATE,
				JSON.stringify(newState)
			);
			return newState;
		});
	};

	const resetCarePlanOnboardingState = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PLAN_ONBOARDING_STATE);
		setCarePlanOnboardingState(initialCarePlanOnboardingState);
	};

	const value = {
		carePlanOnboardingState,
		mutateCarePlanOnboardingState,
		resetCarePlanOnboardingState,
	};

	return (
		<CarePlanOnboardingContext.Provider value={value}>
			{children}
		</CarePlanOnboardingContext.Provider>
	);
};
