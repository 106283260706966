export const authRoutes = {
	login: '/client/auth/login',
	passwordReset: '/client/auth/reset-password',
	onboarding: {
		home: '/client/auth/onboarding',
		platform: {
			home: '/client/auth/onboarding/platform',
			personalInfo: '/client/auth/onboarding/platform/personal',
			accountInfo: '/client/auth/onboarding/platform/account',
			authCode: '/client/auth/onboarding/platform/verify',
		},
		carePlan: {
			home: '/client/auth/onboarding/care-plan',
			relationship: '/client/auth/onboarding/care-plan/relationship',
			info: '/client/auth/onboarding/care-plan/info',
			personal: '/client/auth/onboarding/care-plan/personal',
			logistics: '/client/auth/onboarding/care-plan/logistics',
			careNeeds: '/client/auth/onboarding/care-plan/care-needs',
		},
		liveIns: {
			home: `/client/auth/onboarding/live-ins`,
			breaks: '/client/auth/onboarding/live-ins/breaks',
			compensations: '/client/auth/onboarding/live-ins/compensations',
			accommodation: '/client/auth/onboarding/live-ins/accommodation',
			overtime: '/client/auth/onboarding/live-ins/overtime',
			accommodation: '/client/auth/onboarding/live-ins/accommodation',
			summary: `/client/auth/onboarding/live-ins/summary`,
		},
	},
};
